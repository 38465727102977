import React from "react"

import Layout from "@components/layout"
import SEO from "@components/seo"

const AndroidPage = () => (
  <Layout bodyClassName='android'>
    <SEO
      title="Android First Notice"
      meta={[
        {
          property: `robots`,
          content: `noindex`,
        },
      ]}
    />

    <section className="stripe stripe--hero stripe--centered">
      <div className="container container--lg">
        <div className="copy">
          <h1 className="pageTitle">Interested in Android?</h1>
          <p className="lede">An Android app isn&rsquo;t ready just yet. If you leave us a way to get in touch, you&rsquo;ll be the first to know when it&rsquo;s ready.</p>
        </div>
      </div>
    </section>

    <section className="stripe stripe--alt stripe--centered" id="furry-form">
      <div className="container">
        <div className="copy">
          <form id="form6" name="form6" className="wufoo topLabel page" acceptCharset="UTF-8" autoComplete="off" encType="multipart/form-data" method="post" noValidate
          action="https://nscramer.wufoo.com/forms/pob54cs1d88gjo/#public">
            <label className="desc" id="title1" htmlFor="Field1">Your name</label>
            <input id="Field1" name="Field1" type="text" className="field text medium input" maxLength="255" tabIndex="1" onKeyUp="" placeholder="Brian" />
            <label className="desc" id="title3" htmlFor="Field3">Your email address</label>
            <input id="Field3" name="Field3" type="email" spellCheck="false" className="field text medium input" maxLength="255" tabIndex="2" placeholder="brian@email.com" />
            <input id="saveForm" name="saveForm" className="btn btn--primary btn--block" type="submit" value="Submit" />
            <input type="hidden" id="idstamp" name="idstamp" value="5K6fsMIeE07nbaXnM6vmqh6H8O7X//OkfRfBjf9pYkI=" />
          </form>
        </div>
      </div>
    </section>
  </Layout>
);

export default AndroidPage
